import { differenceInDays, format, getDefaultOptions } from "date-fns";
import { sortBy } from "lodash";

import {
  CHART_FILTER_FIELDS,
  ERROR_MESSAGES,
  Partners,
  REPORT_CHANNEL_COLORS,
  REPORT_NAMES,
  REPORT_PARTNER_COLORS,
  REPORT_TRANSACTION_COLORS,
  TimeBuckets,
  commonFormatDate,
  mediumFormatDate,
  ReportGroupIntervals,
} from "@APP/constants";
import {
  ChannelsReportData,
  ChartData,
  LineData,
  PeakTrafficDataResponse,
  PeakTrafficReportData,
  RTPDeliveryChannel,
  Report,
  ReportDataType,
  ReportTransactionStatus,
  RtpReportData,
  REPORT_ERP,
  DateDefaultOptions,
} from "@APP/types";
import { ERROR_CODES, ERROR_MESSAGE_OVERRIDES } from "@APP/services/api";

/**
 * A wrapper around regular "setTimeout" that can be awaited inside async/await code block.
 * @param ms - milliseconds
 */
export const timeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Returns greeting message based on current day time.
 */
export const getGreeting = (): string => {
  const hours = new Date().getHours();
  if (hours >= 4 && hours < 12) return "Good Morning";
  if (hours >= 12 && hours <= 18) return "Good Afternoon";
  if (hours > 18 && hours <= 23) return "Good Evening";

  return "Good Night";
};

/**
 * Extracts error message text from the error object.
 * @param error
 * @param fallbackMessage - optional fallback message
 * @param config - { printServerErrorCode: boolean } - whether to print the code for server error
 */
export const formatErrorMessage = (
  error: any,
  fallbackMessage = ERROR_MESSAGES.COMMON,
  config: { printServerErrorCode: boolean } = { printServerErrorCode: false },
): string => {
  let errorMessage = fallbackMessage;
  if (!error) {
    errorMessage = "";
  } else if (typeof error === "string") {
    errorMessage = error;
  } else if (Array.isArray(error)) {
    errorMessage = error.reduce((prev, curr) => `${prev}${prev ? ";" : ""} ${String(curr)}`, "");
  } else if (typeof error === "object") {
    errorMessage = error?.response?.data?.errorMessage ?? error?.message ?? fallbackMessage;
    if (config.printServerErrorCode && error?.response?.data?.errorCode) {
      errorMessage = `Code ${error?.response?.data?.errorCode}: ${errorMessage}`;
    }
  }

  return errorMessage;
};

/**
 * Returns only digits from a string.
 * @param text - the string to strip off non-digit characters from
 */
export const getOnlyDigits = (text: string) => {
  return text
    .split("")
    .filter((digit) => /[0-9+]/.test(digit))
    .join("");
};

/**
 * Returns valid UK phone number.
 * @param phone
 */
export const convertUKPhoneNumber = (phone: string) => {
  const formattedNum = getOnlyDigits(phone);

  if (formattedNum.startsWith("+447") && formattedNum.length === 13) {
    return formattedNum;
  } else if (formattedNum.startsWith("07") && formattedNum.length === 11) {
    return formattedNum.replace("0", "+44");
  } else if (formattedNum.startsWith("+4407")) {
    return formattedNum.replace("+4407", "+447");
  } else if (formattedNum.startsWith("44")) {
    return formattedNum.replace("44", "+44");
  }

  return formattedNum;
};

/**
 *
 * @param value
 * @param currency
 * @returns Formatted string with currency symbol and two decimals.
 */
export const formatCurrency = (value: string = "0", currency: string = "GBP") => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  }).format(Number(value));
};

export const setIdTab = (index: number): Record<string, string> => ({
  id: `full-width-tab-${index}`,
  "aria-controls": `full-width-tabpanel-${index}`,
});

export enum STATUS_SETTLED {
  FULFILLED = "fulfilled",
  REJECTED = "rejected",
}

export const convertStringWithUpperRegisterFirstLetter = (str?: string | null) => {
  if (!str) return "";

  return str[0].toUpperCase() + str.slice(1);
};

export const getCurrencySymbol = (currency: string = "GBP") => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  })
    .format(0)
    .replace(/[\d., ]/g, "");
};

export const getPriceInNumberFormat = (price: string, currency: string = "GBP"): number => {
  return price.includes(getCurrencySymbol(currency))
    ? +price.replaceAll(",", "").slice(1)
    : +price.replaceAll(",", "");
};

export const generateDataKeys = (reportName: keyof typeof REPORT_NAMES, data: ReportDataType[]) => {
  if (reportName === REPORT_NAMES.peakTraffic) {
    const dataWithType = [...data] as PeakTrafficReportData[];

    return stripDuplicates(
      dataWithType.map(({ dateTimeBucket }) => format(new Date(dateTimeBucket), mediumFormatDate)),
    );
  }

  return;
};

export const stripDuplicates = <T>(dataArray: T[]) =>
  dataArray.filter((entry, index) => dataArray.indexOf(entry) === index);

export const lightenDarkenHexColor = (hexColour: string, percent: number) => {
  const colorAsNumber = parseInt(hexColour.replace(/^#/, ""), 16);
  const amount = Math.round(2.55 * percent);
  const R = (colorAsNumber >> 16) + amount;
  const G = ((colorAsNumber >> 8) & 0x00ff) + amount;
  const B = (colorAsNumber & 0x0000ff) + amount;
  const colourString = (0x1000000 + (R << 16) + (G << 8) + B).toString(16).slice(1);

  return "#" + colourString;
};

export const randomColor = () => {
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += Math.floor(Math.random() * 10);
  }

  return color;
};

export const generateInitialDatesFromReport = (
  reportName: keyof typeof REPORT_NAMES,
  data: ReportDataType[],
) => {
  switch (reportName) {
    case REPORT_NAMES.peakTraffic: {
      const filteredData = [...(data as PeakTrafficReportData[])];

      return stripDuplicates(
        filteredData.map(({ dateTimeBucket }) =>
          format(new Date(dateTimeBucket), commonFormatDate),
        ),
      );
    }
    default: {
      const filteredData = [...(data as RtpReportData[])];

      return stripDuplicates(filteredData.map(({ interval }) => interval.from.split("T")[0]));
    }
  }
};

export const formatReportPayload = (
  name: keyof typeof REPORT_NAMES,
  data: ReportDataType[] | PeakTrafficDataResponse[],
) =>
  name !== REPORT_NAMES.peakTraffic
    ? (data as ReportDataType[])
    : (data as PeakTrafficDataResponse[]).map(
        ({ totalSent, dateTimeBucket, partner }) =>
          ({
            total: totalSent,
            partner: Partners[partner],
            dateTimeBucket,
          } as PeakTrafficReportData),
      );

export const reportDataFormatter = (reportName: keyof typeof REPORT_NAMES, report: Report) => {
  const { data, filters } = report;
  const {
    selectedDates,
    selectedPartners,
    selectedStatus,
    selectedChannels,
    selectedBanks,
    selectedErps,
  } = filters;

  if (reportName === REPORT_NAMES.peakTraffic) {
    let filteredData = [...(data as PeakTrafficReportData[])];
    if (selectedPartners && !!filteredData.length)
      filteredData = filteredData.filter(({ partner }) =>
        selectedPartners.includes(Partners[partner]),
      );

    if (selectedDates && !!filteredData.length)
      filteredData = filteredData.filter(({ dateTimeBucket }) =>
        selectedDates.includes(format(new Date(dateTimeBucket), commonFormatDate)),
      );

    return Object.values(TimeBuckets).map((timeBucketTime) => {
      // initialised as any, but returned as LineData
      const formattedData: any = {
        name: timeBucketTime,
      };

      filteredData.forEach(({ dateTimeBucket, total }) => {
        const formattedDateTimeBucket = format(new Date(dateTimeBucket), mediumFormatDate);
        formattedData[formattedDateTimeBucket] = dateTimeBucket.includes(timeBucketTime)
          ? total
          : 0;

        return;
      });

      return formattedData as LineData;
    });
  }

  let filteredData = [...(data as RtpReportData[])];
  const reportPartners = stripDuplicates(data.map(({ partner }) => partner));

  if (selectedPartners && !!filteredData.length)
    filteredData = filteredData.filter(({ partner }) =>
      selectedPartners.includes(Partners[partner]),
    );

  if (selectedDates && !!filteredData.length)
    filteredData = filteredData.filter(({ interval }) =>
      selectedDates.includes(format(new Date(interval.from), commonFormatDate)),
    );

  // dertermine if the data has the status / channel field to prevent error
  if (selectedStatus && !!filteredData.length && !!filteredData[0].status)
    filteredData = sortBy(
      filteredData.filter(({ status }) =>
        selectedStatus.includes(status as ReportTransactionStatus),
      ),
      CHART_FILTER_FIELDS.status,
    );

  if (selectedChannels && !!filteredData.length && !!filteredData[0].channel)
    filteredData = sortBy(
      filteredData.filter(
        ({ channel }) => selectedChannels.includes(channel as RTPDeliveryChannel),
        CHART_FILTER_FIELDS.channel,
      ),
    );

  if (selectedBanks && !!filteredData.length && !!filteredData[0].bank)
    filteredData = sortBy(
      filteredData.filter(({ bank }) => selectedBanks.includes(bank as string)),
      "total",
    ).reverse();

  if (selectedErps && !!filteredData.length && !!filteredData[0].erp)
    filteredData = sortBy(
      filteredData.filter(({ erp }) => selectedErps.includes(erp as REPORT_ERP)),
      "total",
    ).reverse();

  switch (reportName) {
    case REPORT_NAMES.declined: {
      return reportPartners.map(
        (chosenPartner) =>
          ({
            name: Partners[chosenPartner],
            color: REPORT_PARTNER_COLORS[chosenPartner],
            value: filteredData
              .filter(({ partner }) => partner === chosenPartner)
              .reduce((acc, { total }) => Math.max(acc, total), 0),
          } as ChartData),
      );
    }
    case REPORT_NAMES.transactions: {
      return filteredData.map(
        ({ partner, total, status, interval: { from } }, index) =>
          ({
            name: partner + " - " + format(new Date(from), commonFormatDate) + " - " + status,
            value: total,
            color: status ? REPORT_TRANSACTION_COLORS[partner][status] : "",
          } as ChartData),
      );
    }

    case REPORT_NAMES.channels: {
      let channelsData = [...(filteredData as ChannelsReportData[])];

      const reportChannels = stripDuplicates(channelsData.map(({ channel }) => channel));
      const reportStatuses = stripDuplicates(channelsData.map(({ status }) => status)).reverse();
      const returnedData: ChartData[] = [];

      reportChannels.forEach((chosenChannel) => {
        reportStatuses.forEach((chosenStatus) => {
          returnedData.push({
            name: `${chosenChannel} - ${chosenStatus}`,
            value: filteredData
              .filter(({ channel, status }) => channel === chosenChannel && status === chosenStatus)
              .reduce((acc, { total }) => acc + total, 0),
            color: REPORT_CHANNEL_COLORS[chosenChannel][chosenStatus],
          } as ChartData);
        });
      });

      return returnedData;
    }
    case REPORT_NAMES.usersDeactive || REPORT_NAMES.usersActive: {
      return filteredData.map(
        ({ partner, total, interval: { from } }) =>
          ({
            name: partner + " - " + format(new Date(from), commonFormatDate),
            value: total,
            color: REPORT_PARTNER_COLORS[partner],
          } as ChartData),
      );
    }
    case REPORT_NAMES.usersRegisteredByBank: {
      return sortBy(
        filteredData.map(
          ({ partner, total, interval: { from }, bank }) =>
            ({
              name:
                convertStringWithUpperRegisterFirstLetter(bank) +
                " - " +
                partner +
                " - " +
                format(new Date(from), commonFormatDate),
              value: total,
              color: randomColor(),
            } as ChartData),
        ),
        "value",
      ).reverse();
    }
    case REPORT_NAMES.usersRegisteredByErp: {
      return filteredData.map(
        ({ partner, total, interval: { from }, erp }) =>
          ({
            name:
              convertStringWithUpperRegisterFirstLetter(erp) +
              " - " +
              partner +
              " - " +
              format(new Date(from), commonFormatDate),
            value: total,
            color: randomColor(),
          } as ChartData),
      );
    }
    default: {
      return filteredData.map(
        ({ partner, total }, index) =>
          ({
            name: partner,
            value: total,
            color: lightenDarkenHexColor(REPORT_PARTNER_COLORS[partner], index * -10),
          } as ChartData),
      );
    }
  }
};

export const getErrorMessageByErrorCode = (errorCode: keyof typeof ERROR_CODES) =>
  ERROR_MESSAGE_OVERRIDES[errorCode]
    ? ERROR_MESSAGE_OVERRIDES[errorCode as keyof typeof ERROR_CODES]
    : ERROR_CODES[errorCode as keyof typeof ERROR_CODES];

// Determine the appropriate grouping when querying the API, based on the difference between the selected intervals.
export const appropriateGrouping = (from: string, to: string) => {
  const dayDifference = differenceInDays(new Date(to), new Date(from));
  if (dayDifference <= 1) return ReportGroupIntervals.daily;
  if (dayDifference <= 7) return ReportGroupIntervals.weekly;

  return ReportGroupIntervals.monthly;
};

/**
 * A wrapper around format that returns the date formatted to the locale set via CONFIG.DATE_LOCALE.
 * If date is not provided, returns undefined.
 * @param date? - date to format
 * @param formatString - format string, e.g. "dd/MM/yyyy"
 */
export const formatDisplayedDate = (date?: Date | string | null, formatString: string = "P") => {
  const dateLocaleOptions: DateDefaultOptions = getDefaultOptions();

  if (!date || (typeof date === "string" && date === "")) return undefined;

  return format(typeof date === "string" ? new Date(date) : date, formatString, dateLocaleOptions);
};
