import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { Box, Button, Card, CardHeader, Divider, Grid, TableCell, TableRow } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import queryString from "query-string";
import { format } from "date-fns";

import { SCREEN_PATHS } from "@APP/navigation";
import {
  AppState,
  clearSearchResults,
  hideLoader,
  setSelectedUser,
  setUserSearchResults,
  showLoader,
} from "@APP/redux";
import { CommonTextField, Page, ScreenHeader, Table } from "@APP/components";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import { API } from "@APP/services";
import { tableStyles as useStyles } from "@APP/styles/table";
import { formatErrorMessage } from "@APP/utils";
import { UserSearchResponse, UserSearchTerms } from "@APP/types";
import { mediumFormatDateTime } from "@APP/constants";

const UpdateUserView = () => {
  const alert = useAlert();
  const handleErrorCodes = useHandleErrorCodes();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { page: initialPage = 0, entries: initialEntries = 10 } = queryString.parse(
    history.location.search,
  );
  const [page, setPage] = useState<number>(initialPage ? Number(initialPage) : 0);
  const [entries, setEntries] = useState<number>(initialEntries ? Number(initialEntries) : 10);
  const [lastPage, setLastPage] = useState<number>(0);
  const { results } = useSelector(
    ({
      userAdmin: {
        users: { results },
      },
    }: AppState) => ({
      results,
    }),
  );

  useEffect(() => {
    (() => {
      fetchSearchResults();
      handlePagination(page, entries);
    })();
  }, [page, entries]);

  const handlePagination = (page: number, entries: number) => {
    const search = createQueryParamsString({ page, entries });
    history.push({
      pathname: history.location.pathname,
      search,
    });
  };

  const fetchSearchResults = async () => {
    try {
      dispatch(showLoader());
      const pagination = { page, entries };
      const { username, phone, org } = values;
      let searchTerms = {} as UserSearchTerms;

      // BE is case sensitive
      if (username?.length) searchTerms.username = username.toLowerCase();
      if (phone?.length) searchTerms.phone = phone.toLowerCase();
      if (org?.length) searchTerms.orgname = org.toLowerCase();

      searchTerms = Object.fromEntries(
        Object.entries(searchTerms).map(([key, value]) => [key, value.trim()]),
      );
      if (Object.values(searchTerms).length) {
        const { data, meta } = await API.getSearchedUsers(searchTerms, pagination);

        if (meta.totalItems) {
          if (meta.totalItems <= entries) {
            setLastPage(1);
          } else {
            const lastPageIndex = Math.ceil(meta.totalItems / entries);
            setLastPage(lastPageIndex);
          }
        }

        dispatch(setUserSearchResults(data));
      }
    } catch (error: any) {
      dispatch(setUserSearchResults([]));
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);

      if (!isHandled) return;
      alert.open("Error", formatErrorMessage(error));
    } finally {
      dispatch(hideLoader());
    }
  };

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    touched,
    values,
    resetForm,
  } = useFormik({
    initialValues: {
      username: "",
      org: "",
      phone: "",
    },
    onSubmit: fetchSearchResults,
  });

  const createQueryParamsString = (params?: any) => {
    const queryStringResults = queryString.parse(history.location.search);

    // create search query url
    return Object.entries({
      page,
      entries,
      ...queryStringResults,
      ...params,
    })
      .filter((query) => {
        // filter out falsy values (except 0)
        return !!query[1] || query[1] === Number(0);
      })
      .reduce((acc, query, index) => {
        const [key, value] = query;

        if (index === 0) acc += `?${key}=${value}`;
        else acc += `&${key}=${value}`;

        return acc;
      }, "");
  };

  const handleOnEntriesChange = (entries: number) => {
    setPage(0);
    setEntries(entries);
    setLastPage(Math.ceil(results.length / entries));
  };

  const clearResults = () => {
    resetForm();
    dispatch(clearSearchResults());
  };

  const onRowClick = (user: UserSearchResponse) => () => {
    dispatch(setSelectedUser(user));
    history.push(`${SCREEN_PATHS.UPDATE_USER}/${user.customerId}`);
  };

  const renderHeader = () => (
    <TableRow className={classes.tableHead}>
      <TableCell>User Name</TableCell>
      <TableCell>Organisation Name</TableCell>
      <TableCell>Phone Number</TableCell>
      <TableCell>Last Login</TableCell>
      {/* leaving this commented out for now as the BE isn't yet returning this */}
      {/* <TableCell>Block Status</TableCell> */}
    </TableRow>
  );
  const renderRows = (entry: UserSearchResponse) => {
    const {
      username,
      orgName,
      phone,
      meta: { lastLoginTime },
    } = entry;

    return (
      <TableRow key={entry.customerId} className={classes.tableRow} onClick={onRowClick(entry)}>
        {/* <TableCell padding="checkbox">
          <Checkbox checked={entry === user} onClick={handleUserSelect(entry)} />
        </TableCell> */}
        <TableCell>{username}</TableCell>
        <TableCell>{orgName}</TableCell>
        <TableCell>{phone}</TableCell>
        <TableCell>
          {lastLoginTime ? format(new Date(lastLoginTime), mediumFormatDateTime) : "N/A"}
        </TableCell>
        {/* waiting for BE to return this */}
        {/* <TableCell>{blocked ? "Blocked" : "Unblocked"}</TableCell> */}
      </TableRow>
    );
  };

  const renderEmptyDataRow = () => (
    <TableRow>
      <TableCell colSpan={4} align="center">
        {`No Matching User Located.`}
      </TableCell>
    </TableRow>
  );

  return (
    <Page title="Update User">
      <form onSubmit={handleSubmit}>
        <Box my={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card elevation={12}>
                <CardHeader title="Search" />
                <Divider />
                <Box p={2}>
                  <Grid container spacing={3}>
                    <Grid item lg={4} xs={12}>
                      <CommonTextField
                        disabled={isSubmitting}
                        error={Boolean(touched.username && errors.username)}
                        fullWidth
                        helperText={touched.username && errors.username}
                        label="User Name"
                        margin="normal"
                        name="username"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="username"
                        value={values.username}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <CommonTextField
                        disabled={isSubmitting}
                        error={Boolean(touched.org && errors.org)}
                        fullWidth
                        helperText={touched.org && errors.org}
                        label="Organisation Name"
                        margin="normal"
                        name="org"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="org"
                        value={values.org}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <CommonTextField
                        disabled={isSubmitting}
                        error={Boolean(touched.phone && errors.phone)}
                        fullWidth
                        helperText={touched.phone && errors.phone}
                        label="Phone Number"
                        margin="normal"
                        name="phone"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="phone"
                        value={values.phone}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Box p={2}>
                  <Grid container item>
                    <Grid item lg={4} xs={false} />
                    <Grid item lg={4} xs={12}>
                      <Button
                        color="primary"
                        disabled={
                          (!values.username && !values.org && !values.phone) || isSubmitting
                        }
                        fullWidth
                        type="submit"
                        variant="contained">
                        Search
                      </Button>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
                        <Button
                          type="reset"
                          disabled={isSubmitting}
                          onClick={clearResults}
                          color="primary"
                          endIcon={<ArrowRightIcon />}
                          variant="text">
                          Clear Results
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </form>
      <Box my={4}>
        <ScreenHeader title="Search Results" />
        <Card elevation={12}>
          <Table
            data={results.slice(page * entries, (page + 1) * entries)}
            renderHeader={renderHeader}
            renderRows={renderRows}
            renderEmptyDataRow={renderEmptyDataRow}
            page={page}
            entries={entries}
            onPageChange={setPage}
            onEntriesChange={handleOnEntriesChange}
            lastPage={lastPage}
          />
        </Card>
      </Box>
    </Page>
  );
};

export default UpdateUserView;
