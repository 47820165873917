import CONFIG from "@APP/config";
import { MetroBankAccountDetails } from "@APP/types";

import request from "../request";

export const getMetroBankAccountsDetails = async (email: string) => {
  const response = await request<MetroBankAccountDetails>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/metro/accounts/${email}`,
  });

  return response.data;
};

export const disableOrEnableBankAccount = async (
  typeOfAction: "disable" | "enable",
  customerId: string,
) => {
  return request({
    method: "PUT",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/metro/accounts/${typeOfAction}`,
    data: { customerId },
  });
};

export const deleteBankAccount = async (customerId: string) => {
  return request({
    method: "DELETE",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/metro/accounts`,
    data: { customerId },
  });
};
